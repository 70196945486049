import LineChartComponent from "@components/admin/LineChartComponent";
import { GraphStat } from "@components/admin/stats/StatsHelper";
import { Box, Card, CardContent, CardHeader, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import LinkIcon from '@material-ui/icons/Link';
import { useState } from "react";

export default function StatsGraphTableComponent(props: {
	tableStats: Map<string, string>,
	tableTitle: string,
	variant: "small" | "large",
	tableLink?: string,
	graphTitle?: string,
	graphStats?: GraphStat[],
	graphTicks?: number,
	graphFormat?: string,
	graphTotalDays?: number,


}) {
	const [show, setShow] = useState(false);
	const shouldRenderGraph = !!props.graphTitle && !!props.graphStats && !!props.graphTicks && !!props.graphFormat && !!props.graphTotalDays;

	const tableStatsMap = [...props.tableStats];
	return <>
		<Card style={{
			// width * 3 + margin * 3 + 16px padding = 1920px width
			width: "32.277%",
			backgroundColor: "#fafafa",
			justifyContent: 'space-between',
			margin: "10px",
			fontSize: '100%'
		}}>

			<CardHeader
				onMouseEnter={() => setShow(true)}
				onMouseLeave={() => setShow(false)}
				style={{ background: '#62AED6', }}
				title={
					<Box>
						<Typography style={{ color: 'rgb(255, 255, 255)', fontWeight: "bold" }}
							variant= {props.variant === "small" ? 'h6' : "h2"}>
							{props.tableTitle}
						</Typography>{show && !!props.tableLink && <IconButton
							style={{
								float: 'right',
								marginTop: '-48px'
							}}
							onClick={() => window.location.href = props.tableLink!}>
							<LinkIcon>
							</LinkIcon>
						</IconButton>}
					</Box>}>
			</CardHeader>
			<CardContent
				style={{
					padding: '16px',
					backgroundColor: "#fafafa"
				}}>
				<Table>
					<TableBody>
						{tableStatsMap.map(([key, value], index) => {
							return <TableRow key={index} style={{ borderBottom: index === tableStatsMap.length - 1 ? 0 : "2px solid rgba(224, 224, 224, 1)"}}>
								<TableCell style={{ border: 0, paddingLeft: 0 }}>
									<Typography style={{ color: 'rgb(96, 69, 63)' }} variant={props.variant === "small" ? 'subtitle2' : "h4"}>
										{key}
									</Typography>
								</TableCell>
								<TableCell style={{ border: 0, paddingRight: 0 }}>
									<Typography style={{ float: "right", color: 'rgb(96, 69, 63)' }} variant= {props.variant === "small" ? 'subtitle2' : "h4"} >
										{value}
									</Typography>
								</TableCell>
							</TableRow>
						})}
					</TableBody>
				</Table>
				{shouldRenderGraph && <Box style={{
					height: 400,
					width: '105%',
					marginLeft: '-16px'
				}}>
					<Typography style={{ marginTop: '24px', marginLeft: '16px' }} variant= {props.variant === "small" ? "body2" : "h6"}>
						{props.graphTitle}
					</Typography>
					<LineChartComponent
						datasets={props.graphStats!}
						ticks={props.graphTicks!}
						format={props.graphFormat!}
						totalDays={props.graphTotalDays!} />
				</Box>}
			</CardContent>
		</Card>
	</>
}


